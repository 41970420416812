import request from '@/utils/request'

/**
 * 折扣管理列表
 */
export const discountList = (params) => {
  return request({
    url: '/discount/list',
    method: 'GET',
    params
  })
}

/**
 * 新增
 */
export const addDiscount = (data) => {
  return request({
    url: '/discount/create',
    method: 'POST',
    data
  })
}

/**
 * 修改
 */
export const editDiscount = (data) => {
  return request({
    url: '/discount/update',
    method: 'POST',
    data
  })
}

/**
 * 删除
 */
export const deleteDiscount = (ids) => {
  return request({
    url: '/discount/delete',
    method: 'POST',
    data: {
      ids: Array.isArray(ids) ? ids : [ids]
    }
  })
}

/**
 * 批量导入
 */
export const multipleImport = (data) => {
  return request({
    url: '/discount/import',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
