<template>
  <el-dialog
    title="批量导入"
    width="600px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-upload
      v-model:file-list="fileList"
      :on-change="handleChange"
      :auto-upload="false"
    >
      <el-button type="info">请选择文件</el-button>
      <el-button type="text" @click="handleDownload">点击下载模板</el-button>
      <template #tip>
        <div class="el-upload__tip">只支持 .xlsx 格式文件</div>
      </template>
    </el-upload>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button
          type="primary"
          @click="submit"
          :disabled="fileList.length === 0"
          :loading="loading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { multipleImport } from '@/api/discount'

const loading = ref(false)

const emits = defineEmits(['update:modelValue'])

const fileList = ref([])

const closed = () => {
  if (loading.value) {
    ElMessage.info('正在导入中...')
    return
  }
  emits('update:modelValue', false)
  fileList.value = []
}

const submit = async () => {
  try {
    loading.value = true
    const formdata = new FormData()
    formdata.append('file', fileList.value[0].raw)
    await multipleImport(formdata)
    ElMessage.success('批量导入成功')
    emits('refresh')
    emits('update:modelValue', false)
    fileList.value = []
  } catch (e) {
    //
  } finally {
    loading.value = false
  }
}

const handleDownload = () => {
  window.location.href = '/static/折扣管理-批量导入模板.xlsx'
}

const handleChange = (file) => {
  if (
    file.raw.type !==
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    ElMessage.error('请上传 xlsx 格式的文件！')
    fileList.value = fileList.value.slice()
    return false
  } else if (file.size / 1024 / 1024 > 10) {
    ElMessage.error('文件最大为10MB！')
    fileList.value = fileList.value.slice()
    return false
  }
  fileList.value = [file]
}
</script>

<style lang="scss" scoped></style>
