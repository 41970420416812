import request from '@/utils/request'

/**
 * 获取店铺列表
 */
export const companyList = (params) => {
  return request({
    url: '/company',
    method: 'GET',
    params
  })
}

/**
 * 新增店铺
 */
export const addCompany = (data) => {
  return request({
    url: '/company',
    method: 'POST',
    data
  })
}

/**
 * 修改店铺
 */
export const editCompany = (data) => {
  return request({
    url: '/company',
    method: 'PUT',
    data
  })
}

/**
 * 删除店铺
 */
export const deleteCompany = (id) => {
  return request({
    url: '/company',
    method: 'DELETE',
    data: {
      company_id: id
    }
  })
}
