<template>
  <div class="manage-container">
    <el-card class="search-header">
      <el-form
        :inline="true"
        ref="SearchFromRef"
        :model="searchForm"
        size="small"
      >
        <div class="search-form">
          <div>
            <el-form-item label="族组/零件号搜索:" prop="keyword">
              <el-input
                v-model="searchForm.keyword"
                placeholder="请输入族组/零件号"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button @click="resetSearch">刷新</el-button>
              <el-button type="primary" @click="getDiscountList"
                >查询</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card>
      <div class="table-header">
        <div style="font-size: 14px; display: flex; align-items: center">
          已选择 {{ multipleSelection.length }} 个
        </div>
        <div>
          <el-button type="warning" size="small" @click="clearSelection">
            清空选择
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click="handleMultipleDelete"
            :disabled="multipleSelection.length === 0"
          >
            批量删除
          </el-button>
          <el-button type="primary" size="small" @click="openMultipleModal">
            + 批量导入
          </el-button>
          <el-button type="primary" size="small" @click="openCreateModal">
            + 新增折扣
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTableRef"
        :data="allAccounts"
        v-loading="loading"
        border
        style="width: 100%"
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          reserve-selection="true"
        ></el-table-column>
        <el-table-column label="族组/零件号" prop="fc_or_pn"> </el-table-column>
        <el-table-column label="折扣（%）" prop="discount"> </el-table-column>
        <el-table-column label="所属店铺" prop="company_name">
        </el-table-column>
        <el-table-column label="描述" prop="desc"> </el-table-column>
        <el-table-column
          label="操作"
          prop="action"
          width="260"
          #default="{ row }"
        >
          <el-button type="primary" size="mini" @click="onEditClick(row)">
            编辑
          </el-button>
          <el-button
            v-if="row.fc_or_pn !== 'ALL'"
            type="danger"
            size="mini"
            @click="onRemoveClick(row)"
          >
            删除
          </el-button>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <create-modal
      v-model="createModalVisible"
      @refresh="getDiscountList"
      :is-edit="isEdit"
      :edit-info="editInfo"
      :company-list="allCompanies"
    />

    <multiple-import
      v-model="multipleImportVisible"
      @refresh="getDiscountList"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { companyList } from '@/api/company'
import { discountList, deleteDiscount } from '@/api/discount'
import CreateModal from './components/CreateModal.vue'
import MultipleImport from './components/MultipleImport.vue'

// 数据相关
const allCompanies = ref([])
const allAccounts = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const multipleTableRef = ref()
const multipleSelection = ref([])

const handleSelectionChange = (val) => {
  multipleSelection.value = val.filter((e) => e.fc_or_pn !== 'ALL')
}

const clearSelection = () => {
  multipleTableRef.value.clearSelection()
}

// 获取店铺列表
const getCompanyList = async () => {
  const result = await companyList()
  allCompanies.value = result.data.list
}

// 获取数据的方法
const loading = ref(false)
const getDiscountList = async () => {
  console.log(searchForm)
  loading.value = true
  const result = await discountList({
    page: page.value,
    size: size.value,
    ...searchForm
  })
  loading.value = false
  allAccounts.value = result.data.list
  total.value = result.data.total || 0
}

// 处理数据不重新加载的问题
onActivated(getCompanyList)
onActivated(getDiscountList)

// 搜索相关
const SearchFromRef = ref(null)
const searchForm = reactive({
  keyword: ''
})

// 重置搜索
const resetSearch = () => {
  SearchFromRef.value.resetFields()
  getDiscountList()
}

/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getDiscountList()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getDiscountList()
}

// 编辑
const editInfo = ref(null)
const isEdit = ref(false)
const onEditClick = (row) => {
  editInfo.value = row
  isEdit.value = true
  createModalVisible.value = true
}

// 删除
const onRemoveClick = (row) => {
  ElMessageBox.confirm('确定要删除该折扣吗？', {
    type: 'warning'
  }).then(async () => {
    try {
      loading.value = true
      await deleteDiscount(row.id)
      ElMessage.success('删除成功')
      // 重新渲染数据
      getDiscountList()
    } catch (e) {
      //
    } finally {
      loading.value = false
    }
  })
}

// 批量删除
const handleMultipleDelete = () => {
  ElMessageBox.confirm('确定要批量删除所选中的折扣吗？', {
    type: 'warning'
  }).then(async () => {
    try {
      loading.value = true
      await deleteDiscount(multipleSelection.value.map((e) => e.id))
      ElMessage.success('批量删除成功')
      // 重新渲染数据
      getDiscountList()
    } catch (e) {
      loading.value = false
    }
  })
}

// 打开新增弹窗
const createModalVisible = ref(false)
const openCreateModal = () => {
  editInfo.value = null
  isEdit.value = false
  createModalVisible.value = true
}

// 打开批量导入弹窗
const multipleImportVisible = ref(false)
const openMultipleModal = () => {
  multipleImportVisible.value = true
}
</script>

<style lang="scss" scoped>
.manage-container {
  .search-header {
    margin-bottom: 20px;

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-form {
      display: flex;
      justify-content: space-between;
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
